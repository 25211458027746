import React from "react";
import "./HeroButtons.sass";

const ButtonWithLightning = ({
  asButton = false,
  label = "play now",
  onClick,
  href = "#",
}) => {
  const isPlayNowPage = window.location.pathname.includes("playnow");

  const commonContent = (
    <>
      <img
        src={
          isPlayNowPage
            ? "/images/main-mask-second-short-button.webp"
            : "/images/main-mask-second-button.webp"
        }
        alt="Button"
        className="image1"
        loading="lazy"
      />
      <img
        src="/images/main-mask-button.webp"
        alt="Lightning"
        className="image2"
        loading="lazy"
      />
      <img
        src="/images/main-helper-button.svg"
        alt="Lightning"
        className="image3"
        loading="lazy"
      />
      <span className="label-1">{label}</span>
    </>
  );

  return asButton ? (
    <button className="button-with-lightning" onClick={onClick}>
      {commonContent}
    </button>
  ) : (
    <a href={href} className="button-with-lightning" target="_blank">
      {commonContent}
    </a>
  );
};

export default ButtonWithLightning;
