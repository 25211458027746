import React, { useEffect, useRef, useState } from "react";
import transition from "../../../transition";
import "./highscores.sass";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";
import FireEffect from "../../FireEffect";
import useResponsiveSettings from "../../useResponsiveSettings.jsx";

gsap.registerPlugin(ScrollTrigger);

const HighScores = () => {
  const videoRef = useRef(null);
  const section75Ref = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const leftSection = {
    mobile: "/images/highscores/Tab-Left-mobile.webp",
    tablet: "/images/highscores/Tab-Left.webp",
    desktop: "/images/highscores/Tab-Left.webp",
  };
  const { backgroundImage: leftSectionBG } = useResponsiveSettings(leftSection);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const highscoresData = [
    { imgSrc: "/images/highscores/Overall.svg", desc: "Overall" },
    { imgSrc: "/images/highscores/Attack.svg", desc: "Attack" },
    { imgSrc: "/images/highscores/Defence.svg", desc: "Defence" },
    { imgSrc: "/images/highscores/Strength.svg", desc: "Strength" },
    { imgSrc: "/images/highscores/Hitpoints.svg", desc: "Hitpoints" },
    { imgSrc: "/images/highscores/Ranged.svg", desc: "Ranged" },
    { imgSrc: "/images/highscores/Prayer.svg", desc: "Prayer" },
    { imgSrc: "/images/highscores/Magic.svg", desc: "Magic" },
    { imgSrc: "/images/highscores/Cooking.svg", desc: "Cooking" },
    { imgSrc: "/images/highscores/Woodcutting.svg", desc: "Woodcutting" },
    { imgSrc: "/images/highscores/Fletching.svg", desc: "Fletching" },
    { imgSrc: "/images/highscores/Fishing.svg", desc: "Fishing" },
    { imgSrc: "/images/highscores/Firemaking.svg", desc: "Firemaking" },
    { imgSrc: "/images/highscores/Crafting.svg", desc: "Crafting" },
    { imgSrc: "/images/highscores/Smithing.svg", desc: "Smithing" },
    { imgSrc: "/images/highscores/Mining.svg", desc: "Mining" },
    { imgSrc: "/images/highscores/Herblore.svg", desc: "Herblore" },
    { imgSrc: "/images/highscores/Agility.svg", desc: "Agility" },
    { imgSrc: "/images/highscores/Thieving.svg", desc: "Thieving" },
    { imgSrc: "/images/highscores/Slayer.svg", desc: "Slayer" },
    { imgSrc: "/images/highscores/Farming.svg", desc: "Farming" },
    { imgSrc: "/images/highscores/Runecraft.svg", desc: "Runecraft" },
    { imgSrc: "/images/highscores/Hunter.svg", desc: "Hunter" },
    { imgSrc: "/images/highscores/Construction.svg", desc: "Construction" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.set(".footer", {
      y: "100%",
    });

    const lenis = new Lenis({
      smooth: true,
      direction: "vertical",
      gestureDirection: "vertical",
      lerp: 0.1,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    lenis.on("scroll", ScrollTrigger.update);

    return () => {
      lenis.destroy();
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) {
      ScrollTrigger.create({
        trigger: section75Ref.current,
        start: "top-=150 top",
        pin: true,
        end: "+=1000",
        scrub: true,
        // markers: true,
      });

      const trigger = ScrollTrigger.create({
        trigger: document.body,
        start: "bottom-=100 bottom",
        onEnter: () => {
          gsap.to(".footer", {
            y: 0,
            duration: 0.4,
            ease: "power2.out",
          });
        },
        onLeaveBack: () => {
          gsap.to(".footer", {
            y: "100%",
            duration: 0.3,
            ease: "power2.out",
          });
        },
      });

      const refreshTrigger = () => ScrollTrigger.refresh();
      window.addEventListener("resize", refreshTrigger);

      return () => {
        window.removeEventListener("resize", refreshTrigger);
        trigger.kill();
      };
    } else {
      gsap.to(".footer", {
        y: 0,
      });
    }
  }, []);

  return (
    <div className="highscores-page">
      <section className="highscores-page-hero">
        <video
          playsInline={true}
          webkit-playsinline="true"
          autoPlay
          loop={true}
          muted
          loading="lazy"
          className="hero-background-video"
          ref={videoRef}
        >
          <source
            src="/videos/run3scape_firstSection_bg_desktop.mov"
            type='video/mp4; codecs="hvc1"'
            media="(min-width: 1025px)"
          />
          <source
            src="/videos/run3scape_firstSection_bg_tablet.mov"
            type='video/mp4; codecs="hvc1"'
            media="(min-width: 768px) and (max-width: 1024px)"
          />
          <source
            src="/videos/run3scape_firstSection_bg_mob.mov"
            type='video/mp4; codecs="hvc1"'
            media="(max-width: 767px)"
          />
          <source
            src="/videos/run3scape_firstSection_bg_desktop.webm"
            type="video/webm"
            media="(min-width: 1025px)"
          />
          <source
            src="/videos/run3scape_firstSection_bg_tablet.webm"
            type="video/webm"
            media="(min-width: 768px) and (max-width: 1024px)"
          />
          <source
            src="/videos/run3scape_firstSection_bg_mob.webm"
            type="video/webm"
            media="(max-width: 767px)"
          />
        </video>
        <div className="highscores-page-hero-container">
          <h1 className="title-h1">
            <span className="green">Run3scape </span>Highscores
          </h1>
          <div className="list-info">
            <div
              className="section_25"
              style={{
                backgroundImage: `url(${leftSectionBG})`,
                backgroundSize: "100% 100%",
                backgroundPosition: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="highscores_box">
                {highscoresData.map((item, index) => (
                  <div
                    key={index}
                    className={`highscores_button_left ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleClick(index)}
                  >
                    <div className="icon">
                      <img src={item.imgSrc} alt={`icon ${index + 1}`} />
                    </div>
                    <p className="desc-2">{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="section_75"
              ref={section75Ref}
              style={{
                backgroundImage: `url("/images/highscores/Tab-Right.webp")`,
                backgroundSize: "100% 100%",
                backgroundPosition: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="highscores_box">
                <div className="highscores_top">
                  <div className="highscores_top_box">
                    <span>#</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>Username</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>Level</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>XP</span>
                  </div>
                </div>
                <img
                  className="line"
                  src="/images/highscores/line-highscores.svg"
                  alt="line"
                />
                <div className="highscores_row">
                  <div className="highscores_top_box">
                    <span>1</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>nicknick</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>22</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>33,100</span>
                  </div>
                </div>
                <div className="highscores_row">
                  <div className="highscores_top_box">
                    <span>2</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>nicaDSknick</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>22</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>33,100</span>
                  </div>
                </div>
                <div className="highscores_row">
                  <div className="highscores_top_box">
                    <span>3</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>nDSicknick</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>22</span>
                  </div>
                  <div className="highscores_top_box">
                    <span>33,100</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FireEffect />
    </div>
  );
};

export default transition(HighScores);
