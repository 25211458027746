import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import transition from "../../../transition";
import "./playnow.sass";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";
import LazyLottie from "../../LazyLottie";
import ButtonWithLightning from "../../HeroButtons/HeroButtons";
import FireEffect from "../../FireEffect";
import useResponsiveSettings from "../../useResponsiveSettings.jsx";

gsap.registerPlugin(ScrollTrigger);

const PlayNow = () => {
  const parallaxBgRef = useRef(null);
  const lottieSections = [
    { sectionRef: useRef(null), lottieRef: useRef(null) },
  ];

  const parallaxBgImg = {
    mobile: "/images/homepage/trybgmobile.webp",
    tablet: "/images/homepage/02trynew-tablet.webp",
    desktop: "/images/homepage/02trynew.webp",
  };

  const { backgroundImage: parallaxBgImage } =
    useResponsiveSettings(parallaxBgImg);

  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.set(".footer", {
      y: "100%",
    });
    const lenis = new Lenis({
      smooth: true,
      direction: "vertical",
      gestureDirection: "vertical",
      lerp: 0.1,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    lenis.on("scroll", ScrollTrigger.update);

    return () => {
      lenis.destroy();
    };
  }, []);
  useEffect(() => {
    const triggers = [];

    lottieSections.forEach(({ sectionRef, lottieRef }) => {
      if (sectionRef.current && lottieRef.current) {
        const trigger = ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top bottom",
          end: "bottom top",
          onEnter: () => {
            lottieRef.current.play();
          },
          onLeave: () => {
            lottieRef.current.pause();
          },
          onEnterBack: () => {
            lottieRef.current.play();
          },
          onLeaveBack: () => {
            lottieRef.current.pause();
          },
        });
        triggers.push(trigger);
      }
    });
    return () => {
      triggers.forEach((trigger) => trigger.kill());
    };
  }, []);
  useEffect(() => {
    if (window.innerWidth > 768) {
      const trigger = ScrollTrigger.create({
        trigger: document.body,
        start: "bottom-=100 bottom",
        onEnter: () => {
          gsap.to(".footer", {
            y: 0,
            duration: 0.4,
            ease: "power2.out",
          });
        },
        onLeaveBack: () => {
          gsap.to(".footer", {
            y: "100%",
            duration: 0.3,
            ease: "power2.out",
          });
        },
      });
      const refreshTrigger = () => ScrollTrigger.refresh();
      window.addEventListener("resize", refreshTrigger);

      return () => {
        window.removeEventListener("resize", refreshTrigger);
        trigger.kill();
      };
    } else {
      gsap.set(".footer", {
        y: "0",
      });
    }
  }, []);
  return (
    <div className="playnow-page">
      <section className="playnow-page-hero">
        <img
          src={parallaxBgImage}
          alt="Button"
          className="playnow-page-background"
          loading="lazy"
          ref={parallaxBgRef}
        />
        <div className="playnow-page-container">
          <div className="playnow-page-title">
            <img src="/images/homepage/Arrow-section2.svg" alt="arrow" />
            <h2 className="title-h2">
              How to Play
              <span className="green">Run3scape?</span>
            </h2>
            <img src="/images/homepage/Arrow-section2.svg" alt="arrow" />
          </div>
          <div className="playnow-page-list">
            <div
              className="card"
              style={{
                backgroundImage: `url("/images/card-bg.webp")`,
                backgroundSize: "100% 100%",
                backgroundPosition: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="card-title">
                <img
                  className="icon"
                  src="/images/icon-card1.webp"
                  alt="icon"
                />
                <h2 className="title-h2">Install Java</h2>
              </div>
              <p className="desc-2">
                Web3Scape runs on Java. That means you'll have to download and
                install Java in order to play. Go to Java's official website{" "}
                <a className="green" target="_blank" href="#">
                  here
                </a>{" "}
                or download below.
              </p>
              <ButtonWithLightning href="#" label="Download Java" />
            </div>
            <div
              className="card"
              style={{
                backgroundImage: `url("/images/card-bg.webp")`,
                backgroundSize: "100% 100%",
                backgroundPosition: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="card-title">
                <img
                  className="icon"
                  src="/images/icon-card2.webp"
                  alt="icon"
                />
                <h2 className="title-h2">Download launcher</h2>
              </div>
              <p className="desc-2">
                The launcher is a jar file which can be ran after installing
                Java. The launcher makes sure you have the latest updates before
                playing Run3scape.
              </p>
              <ButtonWithLightning href="#" label="Download Launcher" />
            </div>
            <div
              className="card"
              style={{
                backgroundImage: `url("/images/card-bg.webp")`,
                backgroundSize: "100% 100%",
                backgroundPosition: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="card-title">
                <img
                  className="icon"
                  src="/images/icon-card3.webp"
                  alt="icon"
                />
                <h2 className="title-h2">Account creation</h2>
              </div>
              <p className="desc-2">
                No Sign-Up needed. Just fill in your username and password for
                the first time and it automatically creates an account for you
                on the go.
              </p>
              <p className="desc-2">
                Still experiencing problems with the Launcher? Try running the{" "}
                <a target="_blank" href="#">
                  Direct Client here.
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="playnow-page-trouble"
        ref={lottieSections[0].sectionRef}
      >
        <div className="playnow-page-trouble-container">
          <div className="text">
            <h2 className="title-h2">
              Still having <span className="green">trouble?</span>
            </h2>
            <img className="line" src="/images/Line.svg" alt="line" />
            <p className="desc-2">
              Nothing to worry about! Our wizard will help you to play
              Run3scape!
            </p>
            <p className="desc-2">
              Our wizard has detected that you have a{" "}
              <span className="green">Windows</span> system. Please check
              ourwizard's solution below!
            </p>
            <p className="desc-2">
              "Download Jarfix below to fix your Java error or download the
              Direct Clientbelow to play Run3scape."
            </p>
            <div className="buttons">
              <ButtonWithLightning href="/#" label="Download Jarfix" />
              <ButtonWithLightning href="/#" label="Download Client" />
            </div>
          </div>
          <LazyLottie
            ref={lottieSections[0].lottieRef}
            autoplay={true}
            loop={true}
            src="/animations/green_dragon_Render_json.json"
            style={{ height: "3.5rem", width: "3.5rem" }}
          />
        </div>
      </section>
      <section className="fire">
        <FireEffect />
      </section>
    </div>
  );
};

export default transition(PlayNow);
