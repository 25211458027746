import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as THREE from "three";

gsap.registerPlugin(ScrollTrigger);

const FireEffect = () => {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const animationFrameRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (containerRef.current && containerRef.current.children.length > 0) {
      Array.from(containerRef.current.children).forEach((child) => {
        if (child.tagName === "CANVAS") {
          containerRef.current.removeChild(child);
        }
      });
    }

    const isMobile = window.innerWidth < 768;
    const isAccountOrHighScores =
      location.pathname === "/account" || location.pathname === "/highscores";

    const delay =
      isMobile && isAccountOrHighScores
        ? 1000
        : isMobile && localStorage.getItem("hasSeenPreloader")
        ? 3400
        : isMobile
        ? 7500
        : 0;

    let scrollFire;

    const stopAnimation = () => {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    };

    const initializeEffect = () => {
      if (rendererRef.current) return;

      const width = window.innerWidth;
      const height = window.innerHeight;

      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);
      containerRef.current.appendChild(renderer.domElement);
      rendererRef.current = renderer;

      const scene = new THREE.Scene();

      const camera = new THREE.OrthographicCamera(
        width / -2,
        width / 2,
        height / 2,
        height / -2,
        1,
        1000
      );
      camera.position.z = 1;

      const uniforms = {
        u_time: { value: 1.0 },
        u_resolution: { value: new THREE.Vector2(width, height) },
        u_speed: { value: new THREE.Vector2(0.7, 0.4) },
        u_shift: { value: 1.6 },
      };

      const fragmentShader = `
        precision mediump float;
        uniform vec2 u_resolution;
        uniform float u_time;
        uniform vec2 u_speed;
        uniform float u_shift;

        float rand(vec2 n) {
          return fract(cos(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
        }

        float noise(vec2 n) {
          const vec2 d = vec2(0.0, 1.0);
          vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
          return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
        }

        float fbm(vec2 n) {
          float total = 0.0, amplitude = 1.0;
          for (int i = 0; i < 4; i++) {
            total += noise(n) * amplitude;
            n += n;
            amplitude *= 0.5;
          }
          return total;
        }

        void main() {
          const vec3 c1 = vec3(0.0, 0.1, 0.0);
          const vec3 c3 = vec3(0.05, 0.3, 0.1);
          const vec3 c4 = vec3(0.1, 0.4, 0.2);
          const vec3 c5 = vec3(0.02, 0.05, 0.02);
          const vec3 c6 = vec3(0.3, 0.4, 0.2);
          const vec3 c2 = vec3(0.15, 0.9, 0.65);

          vec2 p = gl_FragCoord.xy / u_resolution.xy * 8.0;
          float q = fbm(p - u_time * 0.1);
          vec2 r = vec2(fbm(p + q + u_time * u_speed.x - p.x - p.y), fbm(p + q - u_time * u_speed.y));
          vec3 c = mix(c1, c2, fbm(p + r)) + mix(c3, c4, r.x) - mix(c5, c6, r.y);
          float grad = gl_FragCoord.y / u_resolution.y;
          gl_FragColor = vec4(c * cos(u_shift * gl_FragCoord.y / u_resolution.y), 1.0);
          gl_FragColor.xyz *= 1.0 - grad;
        }
      `;

      const shaderMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        fragmentShader: fragmentShader,
      });

      const geometry = new THREE.PlaneGeometry(width, height);
      const plane = new THREE.Mesh(geometry, shaderMaterial);
      scene.add(plane);

      const animate = () => {
        animationFrameRef.current = requestAnimationFrame(animate);
        uniforms.u_time.value += 0.002;
        renderer.render(scene, camera);
      };

      const startAnimation = () => {
        if (!animationFrameRef.current) animate();
      };

      scrollFire = ScrollTrigger.create({
        trigger: containerRef.current,
        start: "top 75%",
        end: "bottom 25%",
        // markers: true,
        onEnter: startAnimation,
        onLeave: stopAnimation,
        onEnterBack: startAnimation,
        onLeaveBack: stopAnimation,
      });
    };

    const timeout = setTimeout(() => {
      initializeEffect();
    }, delay);

    return () => {
      clearTimeout(timeout);
      if (rendererRef.current) {
        rendererRef.current.dispose();
        rendererRef.current = null;
      }
      stopAnimation();
      if (scrollFire) scrollFire.kill();
    };
  }, [location]);

  return <div ref={containerRef} className="fire-container" />;
};

export default FireEffect;
