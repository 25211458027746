import React, { useEffect, useRef, useState, forwardRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

const LazyLottie = forwardRef(({ src, preload = false, ...props }, ref) => {
  const playerRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(preload);
  const containerRef = useRef(null);

  useEffect(() => {
    let observer;
    if (containerRef.current && !preload) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsLoaded(true);
            if (observer && containerRef.current) {
              observer.unobserve(containerRef.current);
            }
          }
        },
        {
          rootMargin: "1000px",
        }
      );
      observer.observe(containerRef.current);
    }

    return () => {
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [preload]);

  React.useImperativeHandle(ref, () => ({
    play: () => playerRef.current && playerRef.current.play(),
    pause: () => playerRef.current && playerRef.current.pause(),
  }));

  return (
    <div ref={containerRef}>
      {isLoaded ? <Player ref={playerRef} src={src} {...props} /> : null}
    </div>
  );
});

export default LazyLottie;
