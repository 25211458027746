import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import transition from "../../../transition";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";
import LazyLottie from "../../LazyLottie";
import ButtonWithLightning from "../../HeroButtons/HeroButtons";
import FireEffect from "../../FireEffect";
import "./features.sass";
import useResponsiveSettings from "../../useResponsiveSettings.jsx";

gsap.registerPlugin(ScrollTrigger);

const Features = () => {
  const videoRef = useRef(null);
  const lottieSections = [
    { sectionRef: useRef(null), lottieRef: useRef(null) },
    { sectionRef: useRef(null), lottieRef: useRef(null) },
    { sectionRef: useRef(null), lottieRef: useRef(null) },
  ];
  const isDesktop = window.innerWidth > 768;

  const lineSection = {
    mobile: "/images/features/line-features-mobile.svg",
    tablet: "/images/features/line-features.svg",
    desktop: "/images/features/line-features.svg",
  };

  const { backgroundImage: lineSectionBottom } =
    useResponsiveSettings(lineSection);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth > 768) {
      gsap.set(".footer", {
        y: "100%",
      });
    }
  });
  useEffect(() => {
    const lenis = new Lenis({
      smooth: true,
      direction: "vertical",
      gestureDirection: "vertical",
      lerp: 0.1,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    lenis.on("scroll", ScrollTrigger.update);

    return () => {
      lenis.destroy();
    };
  }, []);
  useEffect(() => {
    const triggers = [];

    lottieSections.forEach(({ sectionRef, lottieRef }) => {
      if (sectionRef.current && lottieRef.current) {
        const trigger = ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top bottom",
          end: "bottom top",
          onEnter: () => {
            lottieRef.current.play();
          },
          onLeave: () => {
            lottieRef.current.pause();
          },
          onEnterBack: () => {
            lottieRef.current.play();
          },
          onLeaveBack: () => {
            lottieRef.current.pause();
          },
        });
        triggers.push(trigger);
      }
    });
    return () => {
      triggers.forEach((trigger) => trigger.kill());
    };
  }, []);

  useEffect(() => {
    const animateUp = (element) => {
      const isMobile = window.innerWidth <= 768;
      const triggger = gsap.fromTo(
        element,
        { opacity: 0, y: 150 },
        {
          duration: 2,
          y: 0,
          opacity: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: element,
            start: isMobile ? "top-=250 80%" : "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );

      return triggger.scrollTrigger;
    };

    const trigggers = [];

    const elements = document.querySelectorAll(".anim-up");
    elements.forEach((element) => {
      const triggger = animateUp(element);
      trigggers.push(triggger);
    });

    return () => {
      trigggers.forEach((triggger) => triggger.kill());
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) {
      const trigger = ScrollTrigger.create({
        trigger: document.body,
        start: "bottom-=100 bottom",
        onEnter: () => {
          gsap.to(".footer", {
            y: 0,
            duration: 0.4,
            ease: "power2.out",
          });
        },
        onLeaveBack: () => {
          gsap.to(".footer", {
            y: "100%",
            duration: 0.3,
            ease: "power2.out",
          });
        },
      });
      const refreshTrigger = () => ScrollTrigger.refresh();
      window.addEventListener("resize", refreshTrigger);

      return () => {
        window.removeEventListener("resize", refreshTrigger);
        trigger.kill();
      };
    } else {
      gsap.set(".footer", {
        y: "0",
      });
    }
  }, []);

  return (
    <div className="features-page">
      <video
        playsInline={true}
        webkit-playsinline="true"
        preload="auto"
        autoPlay
        loop
        muted
        loading="lazy"
        className="hero-background-video"
        ref={videoRef}
      >
        <source src="/videos/Tornado-vp9-chrome.webm" type="video/webm" />
        <source src="/videos/Tornado.mp4" type="video/mp4" />
      </video>
      <section
        className="features-page-hero"
        ref={lottieSections[0].sectionRef}
      >
        <div className="features-page-hero-container anim-up">
          <h1 className="title-h1">
            {" "}
            <span className="green">features</span>
          </h1>
          <div className="features-info">
            <div className="illustration">
              <LazyLottie
                ref={lottieSections[0].lottieRef}
                autoplay
                loop={true}
                src="/animations/demon_render_stars.json"
                className="illustration-lotty"
              />
              <img
                src="/images/features/features-bg3.webp"
                alt=""
                className="illustration-bg"
              />
            </div>
            <div className="features-info-text">
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Attack.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Attack</h3>
                </div>
                <p className="desc-1">
                  Run3scape runs on Java. That means you'll have to download and
                  install Java in order to play. Go to Java's official website{" "}
                  <a
                    className="green"
                    target="_blank"
                    href="https://www.java.com/ru/download/"
                  >
                    here
                  </a>{" "}
                  or download below.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Strength.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Strength</h3>
                </div>
                <p className="desc-1">
                  Enhances your melee damage, allowing you to hit harder.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Defence.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Defence</h3>
                </div>
                <p className="desc-1">
                  Improves your ability to withstand attacks and reduces damage
                  taken.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Ranged.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Ranged</h3>
                </div>
                <p className="desc-1">
                  Increases your accuracy and effectiveness with ranged weapons
                  like bows and crossbows.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Prayer.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Prayer</h3>
                </div>
                <p className="desc-1">
                  Allows you to invoke divine powers for protection, healing,
                  and combat boosts.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Magic.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Magic</h3>
                </div>
                <p className="desc-1">
                  Enhances your ability to cast spells and increases spell
                  effectiveness.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Runecraft.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Runecrafting</h3>
                </div>
                <p className="desc-1">
                  Enables you to create runes used for casting magic spells.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img className="line" src={lineSectionBottom} alt="line" />
      </section>
      <section
        className="features-page-hero second-section"
        ref={lottieSections[1].sectionRef}
      >
        <div className="features-page-hero-container anim-up">
          <div className="features-info">
            <div className="features-info-text">
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Hitpoints.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">hp</h3>
                </div>
                <p className="desc-1">
                  Increases your overall health and vitality, allowing you to
                  take more damage.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Agility.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Agility</h3>
                </div>
                <p className="desc-1">
                  Improves your ability to traverse obstacles and move swiftly.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Herblore.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Herblore</h3>
                </div>
                <p className="desc-1">
                  Allows you to create potions and other beneficial mixtures
                  using herbs.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Thieving.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Thieving</h3>
                </div>
                <p className="desc-1">
                  Enhances your ability to pickpocket, steal, and perform other
                  clandestine activities.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Crafting.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Crafting</h3>
                </div>
                <p className="desc-1">
                  Enables you to create and repair various items, including
                  weapons, armor, and jewelry.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Fletching.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Fletching</h3>
                </div>
                <p className="desc-1">
                  Allows you to make ranged weapons like arrows and bows.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Construction.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Construction</h3>
                </div>
                <p className="desc-1">
                  Allows you to build and upgrade structures and furniture in
                  your personal space or base.
                </p>
              </div>
            </div>
            <div className="illustration">
              <LazyLottie
                ref={lottieSections[1].lottieRef}
                autoplay={false}
                loop={true}
                src="/animations/angel_render_json.json"
                className="illustration-lotty"
                preload={!isDesktop}
              />
              <img
                src="/images/features/65.webp"
                alt=""
                className="illustration-bg"
              />
            </div>
          </div>
        </div>
        <img className="line" src={lineSectionBottom} alt="line" />
      </section>
      <section
        className="features-page-hero third-section"
        ref={lottieSections[2].sectionRef}
      >
        <div className="features-page-hero-container anim-up">
          <div className="features-info">
            <div className="illustration">
              <LazyLottie
                ref={lottieSections[2].lottieRef}
                autoplay={false}
                loop={true}
                src="/animations/eagle_render_json.json"
                className="illustration-lotty"
                preload={!isDesktop}
              />
              <img
                src="/images/features/BGforvisual03.webp"
                alt=""
                className="illustration-bg"
              />
            </div>
            <div className="features-info-text">
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Slayer.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Slayer</h3>
                </div>
                <p className="desc-1">
                  Enhances your ability to hunt and kill specific monsters
                  assigned by slayer masters.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Hunter.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Hunter</h3>
                </div>
                <p className="desc-1">
                  Improves your ability to trap and hunt wild creatures.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Mining.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Mining</h3>
                </div>
                <p className="desc-1">
                  Increases your efficiency in extracting ores and gems from
                  rocks.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Smithing.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Smithing</h3>
                </div>
                <p className="desc-1">
                  Allows you to forge weapons, armor, and tools from metal ores.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Fishing.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Fishing</h3>
                </div>
                <p className="desc-1">
                  Enhances your ability to catch fish from various bodies of
                  water.
                </p>
              </div>
              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Cooking.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Cooking</h3>
                </div>
                <p className="desc-1">
                  Allows you to prepare food that can heal and provide other
                  benefits.{" "}
                </p>
              </div>

              <div className="highscores_box">
                <div className="highscores_box-title">
                  <div className="icon">
                    <img src="/images/highscores/Firemaking.svg" alt="icon" />
                  </div>
                  <h3 className="label-1">Firemaking</h3>
                </div>
                <p className="desc-1">
                  Increases your ability to create and maintain fires, which can
                  be used for cooking and other purposes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fire">
        <FireEffect />
      </section>
    </div>
  );
};

export default transition(Features);
