import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Footer.sass";
import { Link, useLocation } from "react-router-dom";
import useResponsiveSettings from "../useResponsiveSettings";

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const footerRef = useRef(null);
  const location = useLocation();

  const footerLinks = [
    { to: "/account", label: "Account Panel" },
    { to: "/features", label: "Features" },
    { to: "#", label: "Guides" },
    { to: "/highscores", label: "Highscores" },
    { to: "#", label: "Vote" },
    { to: "#", label: "Community" },
    { to: "#", label: "Java" },
    { to: "#", label: "Launcher" },
    { to: "#", label: "Direct Client" },
  ];

  const footerBackground = {
    mobile: "/images/footer/footerbg-mobile2.webp",
    tablet: "/images/footer/footerbg-tablet.webp",
    desktop: "/images/footer/footerbg.webp",
  };

  const { backgroundImage: footerBg } = useResponsiveSettings(footerBackground);

  useEffect(() => {
    const updateFooterPosition = () => {
      if (window.innerWidth >= 768) {
        footerRef.current.style.position = "fixed";
      } else {
        footerRef.current.style.position = "relative";
      }
    };

    updateFooterPosition();

    window.addEventListener("resize", updateFooterPosition);

    return () => {
      window.removeEventListener("resize", updateFooterPosition);
    };
  }, []);

  return (
    <footer ref={footerRef} className="footer">
      <div
        className="footer-content"
        style={{
          backgroundImage: `url(${footerBg})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="logo">
          <img
            src="/images/header/Logo_Horizontal.webp"
            alt="Logo"
            loading="lazy"
            className="logo"
          />
          <p className="desc-2">© Copyright 2024 Run3scape </p>
        </div>
        <div className="socials">
          <h3 className="title-h2">
            Bringing you back <span className="green">what you loved!</span>
          </h3>
          <div className="socials-list">
            <a target="_blank" href="https://x.com/RUN3SCAPE_eth">
              <img className="icon" src="/images/footer/x.svg" alt="icon" />
              <img
                className="blur"
                src="/images/footer/mini-elipse.webp"
                alt="blur"
              />
            </a>
            <a target="_blank" href="https://t.me/RUN3SCAPE">
              <img
                className="icon"
                src="/images/footer/telegram.svg"
                alt="icon"
              />
              <img
                className="blur"
                src="/images/footer/mini-elipse.webp"
                alt="blur"
              />
            </a>
            <a target="_blank" href="https://discord.gg/MUB5G3zwem">
              <img
                className="icon"
                src="/images/footer/discord.svg"
                alt="icon"
              />
              <img
                className="blur"
                src="/images/footer/mini-elipse.webp"
                alt="blur"
              />
            </a>
            <a href="#">
              <img className="icon" src="/images/footer/reit.svg" alt="icon" />
              <img
                className="blur"
                src="/images/footer/mini-elipse.webp"
                alt="blur"
              />
            </a>
            <a href="#">
              <img className="icon" src="/images/footer/horse.svg" alt="icon" />
              <img
                className="blur"
                src="/images/footer/mini-elipse.webp"
                alt="blur"
              />
            </a>
          </div>
        </div>
        <div className="nav-bar">
          <div className="resorces">
            <h3 className="title-h2">Run3scape</h3>
            <h3 className="title-h2">Resources</h3>
          </div>
          <div className="nav-links">
            {footerLinks.map((item, index) => (
              <div className="nav-item" key={index}>
                <Link className="nav-link desc-2" to={item.to}>
                  {item.label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
