import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import "./home.sass";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import transition from "../../../transition";
import Lenis from "@studio-freight/lenis";
import FireEffect from "../../FireEffect.jsx";
import Preloader from "../../preloader/Preloader";
import ButtonWithLightning from "../../HeroButtons/HeroButtons";
import LazyLottie from "../../LazyLottie";
import useResponsiveSettings from "../../useResponsiveSettings.jsx";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const [loading, setLoading] = useState(true);
  const contentRef = useRef(null);
  const parallaxRef = useRef(null);
  const shieldRef = useRef(null);
  const lottieRef = useRef(null);
  const videoRef = useRef(null);
  const scrollRef = useRef(null);
  const horizontalRef = useRef(null);
  const slideCounterRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isHorizontalScrollEntered, setIsHorizontalScrollEntered] =
    useState(false);
  const footerRef = useRef(null);
  const lottieRefs = useRef([
    { first: useRef(null), second: useRef(null) },
    { first: useRef(null), second: useRef(null) },
    { first: useRef(null), second: useRef(null) },
  ]);
  const isDesktop = window.innerWidth > 768;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("load", () => {
      ScrollTrigger.refresh();
    });
  }, []);

  useEffect(() => {
    const lenis = new Lenis({
      smooth: true,
      direction: "vertical",
      gestureDirection: "vertical",
      lerp: 0.1,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    lenis.on("scroll", ScrollTrigger.update);

    return () => {
      lenis.destroy();
    };
  }, []);

  const lottieSections = [
    { sectionRef: useRef(null), lottieRef: useRef(null) },
    { sectionRef: useRef(null), lottieRef: useRef(null) },
    { sectionRef: useRef(null), lottieRef: useRef(null) },
  ];
  const parallaxBgRef = useRef(null);

  const parallaxBgImg = {
    mobile: "/images/homepage/02trynew-mobile.webp",
    tablet: "/images/homepage/02trynew-tablet.webp",
    desktop: "/images/homepage/02trynew.webp",
  };

  const borderSlide = {
    mobile: "/images/homepage/border-triangle-mobile.svg",
    tablet: "/images/homepage/border-triangle-tablet.svg",
    desktop: "/images/homepage/border-triangle.svg",
  };
  const heroLineLeft = {
    mobile: "/images/homepage/Left_Line-mobile.svg",
    tablet: "/images/homepage/Left_Line.svg",
    desktop: "/images/homepage/Left_Line.svg",
  };
  const heroLineRight = {
    mobile: "/images/homepage/Right_Line-mobile.svg",
    tablet: "/images/homepage/Right_Line.svg",
    desktop: "/images/homepage/Right_Line.svg",
  };
  const lineSection = {
    mobile: "/images/features/line-features-mobile.svg",
    tablet: "/images/features/line-features.svg",
    desktop: "/images/features/line-features.svg",
  };

  const { backgroundImage: parallaxBgImage, deviceType } =
    useResponsiveSettings(parallaxBgImg);

  const { backgroundImage: borderSlideImage } =
    useResponsiveSettings(borderSlide);

  const { backgroundImage: heroLineArrowLeft } =
    useResponsiveSettings(heroLineLeft);

  const { backgroundImage: heroLineArrowRight } =
    useResponsiveSettings(heroLineRight);

  const { backgroundImage: lineSectionBottom } =
    useResponsiveSettings(lineSection);

  const handlePreloaderComplete = () => {
    gsap.to(contentRef.current, {
      visibility: "visible",
      opacity: 1,
      duration: 0.5,
    });
    setLoading(false);
  };

  useEffect(() => {
    const triggers = [];

    const stoneSpeeds = [
      { speedX: 300, speedY: 150 },
      { speedX: -150, speedY: 300 },
      { speedX: 400, speedY: 200 },
      { speedX: -200, speedY: -170 },
    ];

    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const xPos = clientX / windowWidth - 0.5;
      const yPos = clientY / windowHeight - 0.5;

      const parallaxElements = parallaxRef.current.querySelectorAll(".stone");

      parallaxElements.forEach((element, index) => {
        const { speedX, speedY } = stoneSpeeds[index] || {
          speedX: 50,
          speedY: 32,
        };

        gsap.to(element, {
          x: xPos * speedX,
          y: yPos * speedY,
          ease: "power2.out",
        });
      });
    };
    if (deviceType === "desktop" && parallaxRef.current) {
      gsap.fromTo(
        parallaxRef.current,
        { opacity: 0, y: 250 },
        {
          duration: 2,
          y: 0,
          opacity: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: parallaxRef.current,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );

      const parallaxTrigger = ScrollTrigger.create({
        trigger: parallaxRef.current,
        start: "top bottom",
        end: "bottom top",
        onEnter: () => {
          window.addEventListener("mousemove", handleMouseMove);
        },
        onLeave: () => {
          window.removeEventListener("mousemove", handleMouseMove);
        },
        onEnterBack: () => {
          window.addEventListener("mousemove", handleMouseMove);
        },
        onLeaveBack: () => {
          window.removeEventListener("mousemove", handleMouseMove);
        },
      });
      triggers.push(parallaxTrigger);
    }

    if (videoRef.current) {
      const videoTrigger = ScrollTrigger.create({
        trigger: ".hero-section",
        start: "top top",
        end: "bottom top",
        onLeave: () => {
          videoRef.current.pause();
        },
        onEnterBack: () => {
          videoRef.current.play();
        },
      });
      triggers.push(videoTrigger);
    }

    lottieSections.forEach(({ sectionRef, lottieRef }) => {
      if (sectionRef.current && lottieRef.current) {
        const trigger = ScrollTrigger.create({
          trigger: sectionRef.current,
          start: "top bottom",
          end: "bottom top",
          onEnter: () => {
            lottieRef.current.play();
          },
          onLeave: () => {
            lottieRef.current.pause();
          },
          onEnterBack: () => {
            lottieRef.current.play();
          },
          onLeaveBack: () => {
            lottieRef.current.pause();
          },
        });
        triggers.push(trigger);
      }
    });

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      triggers.forEach((trigger) => trigger.kill());
    };
  }, [deviceType]);

  useEffect(() => {
    const animateUp = (element) => {
      gsap.fromTo(
        element,
        { opacity: 0, y: 150 },
        {
          duration: 2,
          y: 0,
          opacity: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: element,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    };

    const elements = document.querySelectorAll(".anim-up");

    elements.forEach((element) => {
      animateUp(element);
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useLayoutEffect(() => {
    const slides = horizontalRef.current.querySelectorAll(".slide");
    setTotalSlides(slides.length);

    if (isDesktop) {
      ScrollTrigger.create({
        trigger: horizontalRef.current,
        start: "top-=300 top",
        // markers: true,
        onEnter: () => {
          lottieRefs.current[0].first.current.play();
          lottieRefs.current[0].second.current.play();
        },
        onLeave: () => {
          lottieRefs.current[0].first.current.pause();
          lottieRefs.current[0].second.current.pause();
        },
      });
      const leftOffset = 50;
      const rightOffset = 150;

      let horizontalScroll = gsap.to(".scroll", {
        xPercent: -67,
        ease: "none",
        scrollTrigger: {
          trigger: horizontalRef.current,
          pin: true,
          scrub: 1,
          start: "top top",
          // markers: "true",
          end: "bottom+=200% bottom",
          onUpdate: (self) => {
            const progress = self.progress;
            const slideIndex = Math.round(progress * (slides.length - 1)) + 1;
            setCurrentSlide(slideIndex);

            const maxX =
              window.innerWidth - slideCounterRef.current.offsetWidth;
            const adjustedMaxX = maxX + rightOffset;
            const adjustedMinX =
              -slideCounterRef.current.offsetWidth - leftOffset;

            gsap.to(slideCounterRef.current, {
              x: progress * adjustedMaxX + adjustedMinX,
              ease: "none",
              overwrite: true,
              duration: 0.1,
            });

            lottieRefs.current.forEach((refs, index) => {
              if (index + 1 === slideIndex) {
                refs.first.current.play();
                refs.second.current.play();
              } else {
                refs.first.current.pause();
                refs.second.current.pause();
              }
            });
          },
          onLeave: () => {
            lottieRefs.current.forEach((refs) => {
              refs.first.current.pause();
              refs.second.current.pause();
            });
          },
          onLeaveBack: () => {
            lottieRefs.current.forEach((refs) => {
              refs.first.current.pause();
              refs.second.current.pause();
            });
          },
          onEnterBack: () => {
            const activeSlideIndex = currentSlide - 1;
            lottieRefs.current[activeSlideIndex].first.current.play();
            lottieRefs.current[activeSlideIndex].second.current.play();
          },
        },
      });
      gsap.set(slideCounterRef.current, {
        x: -slideCounterRef.current.offsetWidth - leftOffset,
      });

      return () => {
        horizontalScroll.kill();
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    }
  }, [isDesktop]);

  useEffect(() => {
    if (!isDesktop) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsHorizontalScrollEntered(true);
            observer.unobserve(scrollRef.current);
          }
        },
        { rootMargin: "0px" }
      );

      if (scrollRef.current) {
        observer.observe(scrollRef.current);
      }

      return () => {
        if (scrollRef.current) {
          observer.unobserve(scrollRef.current);
        }
      };
    }
  }, [isDesktop]);

  useEffect(() => {
    if (!isDesktop && isHorizontalScrollEntered) {
      lottieRefs.current.forEach((refs) => {
        if (refs.first.current) refs.first.current.play();
        if (refs.second.current) refs.second.current.play();
      });
    }
  }, [isDesktop, isHorizontalScrollEntered]);

  useEffect(() => {
    if (!isDesktop) {
      const scrollContainer = scrollRef.current;
      let startX;
      let currentX;

      const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
      };

      const handleTouchMove = (e) => {
        currentX = e.touches[0].clientX;
      };

      const handleTouchEnd = () => {
        const deltaX = startX - currentX;
        if (deltaX > 50 && currentSlide < totalSlides) {
          goToNextSlide();
        } else if (deltaX < -50 && currentSlide > 1) {
          goToPrevSlide();
        }
      };

      scrollContainer.addEventListener("touchstart", handleTouchStart);
      scrollContainer.addEventListener("touchmove", handleTouchMove);
      scrollContainer.addEventListener("touchend", handleTouchEnd);

      return () => {
        scrollContainer.removeEventListener("touchstart", handleTouchStart);
        scrollContainer.removeEventListener("touchmove", handleTouchMove);
        scrollContainer.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [isDesktop, currentSlide, totalSlides]);

  const goToNextSlide = () => {
    setCurrentSlide((prev) => {
      if (prev >= totalSlides) return prev;
      const newSlide = prev + 1;

      if (lottieRefs.current[prev - 1]?.first.current) {
        lottieRefs.current[prev - 1].first.current.pause();
      }
      if (lottieRefs.current[prev - 1]?.second.current) {
        lottieRefs.current[prev - 1].second.current.pause();
      }

      gsap.to(scrollRef.current, {
        x: `-${(newSlide - 1) * 100}%`,
        duration: 0.5,
      });

      setTimeout(() => {
        if (lottieRefs.current[newSlide - 1]?.first.current) {
          lottieRefs.current[newSlide - 1].first.current.play();
        }
        if (lottieRefs.current[newSlide - 1]?.second.current) {
          lottieRefs.current[newSlide - 1].second.current.play();
        }
      }, 300);

      return newSlide;
    });
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prev) => {
      if (prev <= 1) return prev;
      const newSlide = prev - 1;

      if (lottieRefs.current[prev - 1]?.first.current) {
        lottieRefs.current[prev - 1].first.current.pause();
      }
      if (lottieRefs.current[prev - 1]?.second.current) {
        lottieRefs.current[prev - 1].second.current.pause();
      }

      gsap.to(scrollRef.current, {
        x: `-${(newSlide - 1) * 100}%`,
        duration: 0.5,
      });

      setTimeout(() => {
        if (lottieRefs.current[newSlide - 1]?.first.current) {
          lottieRefs.current[newSlide - 1].first.current.play();
        }
        if (lottieRefs.current[newSlide - 1]?.second.current) {
          lottieRefs.current[newSlide - 1].second.current.play();
        }
      }, 300);

      return newSlide;
    });
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      ScrollTrigger.create({
        trigger: document.body,
        start: "bottom-=100 bottom",
        onEnter: () => {
          gsap.to(".footer", {
            y: 0,
            duration: 0.4,
            ease: "power2.out",
          });
        },
        onLeaveBack: () => {
          gsap.to(".footer", {
            y: "100%",
            duration: 0.3,
            ease: "power2.out",
          });
        },
      });
      const refreshTrigger = () => ScrollTrigger.refresh();
      window.addEventListener("resize", refreshTrigger);

      return () => {
        window.removeEventListener("resize", refreshTrigger);
      };
    } else {
      gsap.set(".footer", {
        y: "0",
      });
    }
  }, []);

  return (
    <>
      {loading && <Preloader onComplete={handlePreloaderComplete} />}
      <div
        ref={contentRef}
        style={{
          visibility: loading ? "hidden" : "visible",
          opacity: 0,
        }}
      >
        <div className="hero-page">
          <section className="hero-section" ref={lottieSections[0].sectionRef}>
            <video
              playsInline={true}
              webkit-playsinline="true"
              preload="auto"
              autoPlay
              loop={true}
              muted
              loading="lazy"
              className="hero-background-video"
              ref={videoRef}
            >
              <source
                src="/videos/run3scape_firstSection_bg_desktop.mov"
                type='video/mp4; codecs="hvc1"'
                media="(min-width: 1025px)"
              />
              <source
                src="/videos/run3scape_firstSection_bg_tablet.mov"
                type='video/mp4; codecs="hvc1"'
                media="(min-width: 768px) and (max-width: 1024px)"
              />
              <source
                src="/videos/run3scape_firstSection_bg_mob.mov"
                type='video/mp4; codecs="hvc1"'
                media="(max-width: 767px)"
              />
              <source
                src="/videos/run3scape_firstSection_bg_desktop.webm"
                type="video/webm"
                media="(min-width: 1025px)"
              />
              <source
                src="/videos/run3scape_firstSection_bg_tablet.webm"
                type="video/webm"
                media="(min-width: 768px) and (max-width: 1024px)"
              />
              <source
                src="/videos/run3scape_firstSection_bg_mob.webm"
                type="video/webm"
                media="(max-width: 767px)"
              />
            </video>
            <div className="hero-content">
              <div className="description-text">
                <p className="desc-1">
                  Welcome to Run3scape, a unique RSPS set in the nostalgic era
                  of 2007. Embark on thrilling quests, master various skills and
                  slay fierce monsters to earn $GOBLIN rewards.
                </p>
                <img
                  src={heroLineArrowLeft}
                  alt="decor"
                  className="decor-line"
                />
              </div>
              <div className="center-cta">
                <img
                  src="/images/homepage/Dash_Lines.svg"
                  alt="decor"
                  className="dashes"
                />
                <img
                  src="/images/homepage/Gem.webp"
                  alt="crystal"
                  className="crystal"
                />
                <h1 className="title-h1">
                  Bringing you back
                  <span className="green">what you loved!</span>
                </h1>
                <div className="lottie-container">
                  <LazyLottie
                    ref={lottieSections[0].lottieRef}
                    autoplay
                    loop={true}
                    src="/animations/demon_render_noLegs.json"
                  />
                  <ButtonWithLightning />
                  <div className="todown">
                    <p className="desc-1 greygreen">Enter the Realm</p>
                    <img
                      loading="lazy"
                      src="/images/homepage/stardown.svg"
                      alt="decor"
                    />
                  </div>
                </div>
              </div>
              <div className="description-text">
                <p className="desc-1">
                  Dive into a world filled with ancient gods, diverse races and
                  epic adventures. Start your journey and reap real-world
                  benefits today!
                </p>
                <img
                  src={heroLineArrowRight}
                  alt="decor"
                  className="decor-line"
                  loading="lazy"
                />
              </div>
            </div>
            <img className="line-bottom" src={lineSectionBottom} alt="line" />
          </section>

          <section className="parallax-section">
            <img
              src={parallaxBgImage}
              alt="Button"
              className="second-background"
              loading="lazy"
              ref={parallaxBgRef}
            />
            <div className="parallax-container">
              <div className="level">
                <p className="desc-1 greygreen">level 01</p>
                <img src="/images/homepage/Icon_shield.svg" alt="decor" />
              </div>
              <div
                className="shield anim-up"
                ref={shieldRef}
                style={{
                  backgroundImage: `url("/images/Shield.webp")`,
                  backgroundSize: "100% 100%",
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="shield-title">
                  <img src="/images/homepage/Arrow-section2.svg" alt="arrow" />
                  <h2 className="title-h2">
                    Why choose
                    <span className="green">Run3scape?</span>
                  </h2>
                  <img src="/images/homepage/Arrow-section2.svg" alt="arrow" />
                </div>
                <p className="desc-2 text-anim">
                  We bring you the best of <strong>2007 (RSPS)</strong>{" "}
                  nostalgia, combined with the innovative power of Web3
                  technology. Run3scape's unique play-to-earn model allows you
                  to earn <strong>$GOBLIN</strong> by completing quests,
                  mastering skills and defeating monsters. This isn't just a
                  game - it's an opportunity to convert your in-game
                  achievements into valuable rewards.
                </p>
                <p className="desc-2 text-anim">
                  Experience fast-paced, <strong>engaging gameplay</strong> with
                  frequent updates and exciting events. Whether you're a veteran
                  player or new to RSPS, our team offers endless opportunities
                  for <strong>adventure</strong> and <strong>earning</strong>.
                </p>
                <img
                  className="bottomimg-shield"
                  src="/images/header/Logo_Shield.webp"
                  alt="shield"
                />
              </div>
              <div className="parallax-effect" ref={parallaxRef}>
                <img
                  src="/images/homepage/Crystal_Stone.webp"
                  alt="Button"
                  className="stone"
                  loading="lazy"
                />
                <img
                  src="/images/homepage/Stone-lapa4.webp"
                  alt="Button"
                  className="stone"
                  loading="lazy"
                />
                <img
                  src="/images/homepage/Crystal_Heart.webp"
                  alt="Button"
                  className="stone"
                  loading="lazy"
                />
                <img
                  src="/images/homepage/Crystal_Stone-reverse.webp"
                  alt="Button"
                  className="stone"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="level">
              <p className="desc-1 greygreen">level 02</p>
              <img src="/images/homepage/Icon_sword.svg" alt="decor" />
            </div>
          </section>
          <section className="horizontal-scroll" ref={horizontalRef}>
            <div className="scroll" ref={scrollRef}>
              <div className="slide">
                <div className="slide-content">
                  <img
                    className="border-triangle"
                    src={borderSlideImage}
                    alt=""
                  />
                  <div className="illustration">
                    <img
                      className="ellipse"
                      src="/images/homepage/Ellipse_bg.webp"
                      alt="bg"
                    />
                    <LazyLottie
                      ref={lottieRefs.current[0].first}
                      autoplay={false}
                      loop={true}
                      src="/animations/red_monster_render_json.json"
                      className="character"
                      preload={!isDesktop}
                    />
                    <LazyLottie
                      ref={lottieRefs.current[0].second}
                      autoplay={false}
                      loop={true}
                      src="/animations/preloader_element.json"
                      className="decor-line"
                      preload={!isDesktop}
                    />
                  </div>
                  <div className="text">
                    <div className="text-title">
                      <img
                        className="medallion"
                        src="/images/homepage/Medallion01.webp"
                        alt="decor"
                      />
                      <h2 className="title-h2">
                        {" "}
                        <span className="green">Slay bosses</span> and earn
                      </h2>
                    </div>
                    <p className="desc-2">
                      Join the adventure in <strong>Run3scape</strong>, where
                      you can fight and defeat powerful bosses to earn{" "}
                      <strong>$GOBLIN</strong>. Each boss you conquer gives you
                      valuable crypto rewards. The more challenging the boss,
                      the bigger the rewards.
                    </p>
                    <p className="desc-2">
                      Sharpen your skills, take on epic battles and grow your
                      $GOBLIN stash with every victory.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slide-content">
                  <img
                    className="border-triangle"
                    src={borderSlideImage}
                    alt=""
                  />
                  <div className="illustration">
                    <img
                      className="ellipse"
                      src="/images/homepage/Ellipse_bg.webp"
                      alt="bg"
                    />
                    <LazyLottie
                      ref={lottieRefs.current[1].first}
                      autoplay={false}
                      loop={true}
                      src="/animations/2guys_Render_json.json"
                      className="character"
                      preload={!isDesktop}
                    />
                    <LazyLottie
                      ref={lottieRefs.current[1].second}
                      autoplay={false}
                      loop={true}
                      src="/animations/preloader_element.json"
                      className="decor-line"
                      preload={!isDesktop}
                    />
                  </div>
                  <div className="text">
                    <div className="text-title">
                      <img
                        className="medallion"
                        src="/images/homepage/Medallion02.webp"
                        alt="decor"
                      />
                      <h2 className="title-h2">
                        <span className="green">Slay</span> other <br />
                        <span className="green">players</span> and earn
                      </h2>
                    </div>
                    <p className="desc-2">
                      Step into the competitive world of Run3scape, where you
                      can engage in thrilling player-versus-player combat to
                      earn $GOBLIN. <strong>Defeat other players</strong> in
                      intense battles and be rewarded with valuable earnings for
                      your victories.{" "}
                    </p>
                    <p className="desc-2">
                      Each fight is a chance to showcase{" "}
                      <strong>your skills and strategy</strong>, with higher
                      rewards for taking down tougher opponents. Compete,
                      dominate and watch your $GOBLIN grow as you rise through
                      the ranks.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slide-content">
                  <img
                    className="border-triangle"
                    src={borderSlideImage}
                    alt=""
                  />
                  <div className="illustration">
                    <img
                      className="ellipse"
                      src="/images/homepage/Ellipse_bg.webp"
                      alt="bg"
                    />
                    <LazyLottie
                      ref={lottieRefs.current[2].first}
                      autoplay={false}
                      loop={true}
                      src="/animations/warriors_render_json.json"
                      className="character"
                      preload={!isDesktop}
                    />
                    <LazyLottie
                      ref={lottieRefs.current[2].second}
                      autoplay={false}
                      loop={true}
                      src="/animations/preloader_element.json"
                      className="decor-line"
                      preload={!isDesktop}
                    />
                  </div>
                  <div className="text">
                    <div className="text-title">
                      <img
                        className="medallion"
                        src="/images/homepage/Medallion02.webp"
                        alt="decor"
                      />
                      <h2 className="title-h2">
                        <span className="green">Craft, gather</span> resources
                        and earn
                      </h2>
                    </div>
                    <p className="desc-2">
                      Immerse yourself in the world of Run3scape, where your
                      dedication to skilling pays off. Enhance your abilities in
                      various skills like mining, fishing, crafting and more to
                      earn $GOBLIN. Every action you perform contributes to your
                      growth and increases your earnings.
                    </p>
                    <p className="desc-2">
                      Whether you're gathering resources or crafting items, your
                      hard work is rewarded. Join the community, develop your
                      skills and see your $GOBLIN stash grow as you master new
                      trades.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-counter" ref={slideCounterRef}>
              <img
                className="line-track"
                src="/images/homepage/line-track-horizontal.svg"
                alt="dec"
              />
              <div className="numbers">
                <p className="desc-2">{currentSlide}</p>
                <p className="desc-2">{totalSlides}</p>
              </div>
              <div className="mobile-navigation">
                <div
                  className={`arrow reverse ${
                    currentSlide === 1 ? "disable" : ""
                  }`}
                  onClick={goToPrevSlide}
                >
                  <img src="/images/homepage/RightArrowSlide.svg" alt="arrow" />
                </div>
                <div className="numbers-mobile">
                  {[...Array(totalSlides)].map((_, index) => (
                    <span
                      key={index}
                      className={`label-1 ${
                        currentSlide === index + 1 ? "active" : ""
                      }`}
                    >
                      {index + 1}
                    </span>
                  ))}
                </div>
                <div
                  className={`arrow ${
                    currentSlide === totalSlides ? "disable" : ""
                  }`}
                  onClick={goToNextSlide}
                >
                  <img src="/images/homepage/RightArrowSlide.svg" alt="arrow" />
                </div>
              </div>
            </div>
          </section>
          <section
            className="complandexplore anim-up"
            ref={lottieSections[1].sectionRef}
          >
            <div className="level">
              <p className="desc-1 greygreen">level 03</p>
              <img src="/images/homepage/iconaxe.svg" alt="decor" />
            </div>
            <div className="complandexplore-content">
              <img
                className="light"
                src="/images/homepage/BG-Blur.webp"
                alt="bg"
              />
              <LazyLottie
                ref={lottieSections[1].lottieRef}
                loop={true}
                src="/animations/bug_render_json.json"
                className="player"
                preload={!isDesktop}
              />
              <div className="complandexplore-content-text">
                <h2 className="title-h2">
                  <span className="green">Complete quests</span>and earn
                </h2>
                <img className="line" src="/images/Line.svg" alt="line" />
                <p className="desc-2">
                  Dive into Run3scape, where completing quests rewards you with
                  real cryptocurrency. Take on fun and challenging missions,
                  solve problems and defeat enemies to earn $GOBLIN. Each quest
                  brings new adventures and earnings.
                </p>
                <p className="desc-2">
                  Whether you're finding hidden items or helping characters,
                  your hard work pays off.
                </p>
              </div>
            </div>
            <img className="line-bottom" src={lineSectionBottom} alt="line" />
          </section>
          <section
            className="complandexplore anim-up reverse"
            ref={lottieSections[2].sectionRef}
          >
            <div className="complandexplore-content">
              <div className="complandexplore-content-text">
                <h2 className="title-h2">
                  <span className="green">Explore the world</span>and earn
                </h2>
                <img
                  className="line line-right"
                  src="/images/Line.svg"
                  alt="line"
                />
                <p className="desc-2">
                  Discover the vast world of Run3scape and earn as you explore.
                  Travel through different lands, uncover hidden secrets and
                  meet interesting characters. Each new place you visit and
                  every discovery you make can earn you $GOBLIN.
                </p>
                <p className="desc-2">
                  Whether you're exploring ancient ruins or magical forests,
                  your adventures are rewarded.
                </p>
              </div>
              <img
                className="light right"
                src="/images/homepage/BG-Blur.webp"
                alt="bg"
              />
              <LazyLottie
                ref={lottieSections[2].lottieRef}
                loop={true}
                src="/animations/barrel_render_json.json"
                className="player-right"
                preload={!isDesktop}
              />
            </div>
          </section>
          <section className="fire">
            <div className="level">
              <p className="desc-1 greygreen">level 04</p>
              <img src="/images/homepage/diamondIcon.svg" alt="decor" />
            </div>
            <FireEffect />
            <div className="hero-content anim-up">
              <div className="description-text">
                <img
                  src={heroLineArrowLeft}
                  alt="decor"
                  className="decor-line"
                  loading="lazy"
                />
              </div>
              <div className="center-cta">
                <img
                  src="/images/homepage/Dash_Lines.svg"
                  alt="decor"
                  className="dashes"
                  loading="lazy"
                />
                <img
                  src="/images/homepage/GlowLvl4.webp"
                  alt="glow"
                  className="glow"
                  loading="lazy"
                />
                <img
                  src="/images/header/Logo_Shield.webp"
                  alt="crystal"
                  className="crystal"
                  loading="lazy"
                />
                <h2 className="title-h2">
                  {" "}
                  <span className="green">Train against monsters</span> and earn
                </h2>
                <p className="desc-2">
                  In the world of Run3scape, fighting and training against
                  monsters is a thrilling way to earn. Battle various creatures,
                  improve your combat skills and gain $GOBLIN as a reward for
                  your efforts. Each monster you defeat and each skill you
                  improve brings you closer to earning more $GOBLIN.
                </p>
                <p className="desc-2">
                  Whether you're taking down fierce beasts or training to become
                  stronger, your hard work pays off.
                </p>
                <div className="lottie-container">
                  <ButtonWithLightning />
                </div>
              </div>
              <div className="description-text">
                <img
                  src={heroLineArrowRight}
                  alt="decor"
                  className="decor-line"
                  loading="lazy"
                />
              </div>
            </div>
          </section>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default transition(Home);
