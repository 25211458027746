import React from "react";
import { Link } from "react-router-dom";
import "./MobileMenu.sass";

const MobileMenu = ({ isOpen, toggleMenu }) => {
  return (
    <div
      className={`mobile-menu ${isOpen ? "open" : ""}`}
      style={{
        backgroundImage: `url(/images/bgmobilemenu-tab.webp)`,
        backgroundSize: "100% 100%",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="mobile-menu-content">
        <Link className="title-h2" to="/" onClick={toggleMenu}>
          Home
        </Link>
        <Link className="title-h2" to="/playnow" onClick={toggleMenu}>
          Play Now
        </Link>
        <Link className="title-h2" to="/features" onClick={toggleMenu}>
          Features
        </Link>
        <Link className="title-h2" to="/highscores" onClick={toggleMenu}>
          Highscores
        </Link>
        <Link
          className="title-h2"
          target="_blank"
          to="https://docs.web3scape.io/"
          onClick={toggleMenu}
        >
          Docs
        </Link>
        <Link className="title-h2" to="/account" onClick={toggleMenu}>
          Account Panel
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
