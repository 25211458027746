import React, { useState, useEffect } from "react";
import "./account.sass";
import transition from "../../../transition";
import ButtonWithLightning from "../../HeroButtons/HeroButtons";
import FireEffect from "../../FireEffect";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useResponsiveSettings from "../../useResponsiveSettings";
import Footer from "../../footer/Footer";

gsap.registerPlugin(ScrollTrigger);

const Account = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showWalletPopup, setShowWalletPopup] = useState(false);
  const [showRedeemPopup, setShowRedeemPopup] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isFocusedUsername, setIsFocusedUsername] = useState(false);
  const [isFocusedPassword, setIsFocusedPassword] = useState(false);
  const [isBigWindow, setIsBigWindow] = useState(false);

  const [points, setPoints] = useState("");
  const [pointsError, setPointsError] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  useEffect(() => {
    const footerElement = document.querySelector(".footer");
    const isMobile = window.innerWidth < 768;

    if (footerElement) {
      if (isMobile) {
        footerElement.style.position = "relative";
      } else {
        footerElement.style.position = isBigWindow ? "relative" : "fixed";
      }
    }
  }, [isBigWindow]);

  const handlePointsChange = (e) => {
    const value = e.target.value;

    if (value === "0") {
      setPointsError("Points cannot be zero.");
    } else {
      setPointsError("");
    }

    setPoints(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.set(".footer", {
      y: "0",
    });
  }, []);

  const handleClaim = (e) => {
    e.preventDefault();
    if (points === "0" || points === "") {
      setPointsError("Points cannot be zero.");
    } else {
      console.log("Claiming points:", points);
    }
  };

  const accountBackground = {
    mobile: "/images/account/accountbg.webp",
    tablet: "/images/account/accountbg.webp",
    desktop: "/images/account/accountbg.webp",
  };

  const accountPanelBackground = {
    mobile: "/images/account/accountpanel-mobile.webp",
    tablet: "/images/account/accountbg.webp",
    desktop: "/images/account/accountbg.webp",
  };

  const accountClaimBackground = {
    mobile: "/images/account/claim-bg-mobile.webp",
    tablet: "/images/account/claim-bg.webp",
    desktop: "/images/account/claim-bg.webp",
  };

  const borderInputTop = {
    mobile: "/images/account/border-input-mobile.svg",
    tablet: "/images/account/claim-bg.webp",
    desktop: "/images/account/border-input.svg",
  };

  const { backgroundImage: accountBg } =
    useResponsiveSettings(accountBackground);

  const { backgroundImage: accountPanelBg } = useResponsiveSettings(
    accountPanelBackground
  );

  const { backgroundImage: accountClaimBg } = useResponsiveSettings(
    accountClaimBackground
  );

  const { backgroundImage: borderInputTopData } =
    useResponsiveSettings(borderInputTop);

  const borderInputCoinDefault = {
    mobile: "/images/account/border-inputsmall-mobile-default.svg",
    tablet: "/images/account/claim-bg.webp",
    desktop: "/images/account/border-input.svg",
  };

  const { backgroundImage: borderInputCoinDefaultData } = useResponsiveSettings(
    borderInputCoinDefault
  );
  const borderInputCoinHover = {
    mobile: "/images/account/border-inputsmall-mobile-hover.svg",
    tablet: "/images/account/claim-bg.webp",
    desktop: "/images/account/border-input.svg",
  };

  const { backgroundImage: borderInputCoinHoverData } =
    useResponsiveSettings(borderInputCoinHover);
  const borderInputCoinError = {
    mobile: "/images/account/border-inputsmall-mobile-default.svg",
    tablet: "/images/account/claim-bg.webp",
    desktop: "/images/account/border-input.svg",
  };

  const { backgroundImage: borderInputCoinErrorData } =
    useResponsiveSettings(borderInputCoinError);

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;

    if (!username) {
      setUsernameError("Username is required.");
      valid = false;
    } else {
      setUsernameError("");
    }

    if (!password) {
      setPasswordError("Password is required.");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      setShowPopup(true);
      setTimeout(() => {
        setShowVideo(true);
        setTimeout(() => {
          setIsVideoVisible(true);
        }, 100);
      }, 500);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "username") {
      setUsername(value);
      if (value) {
        setUsernameError("");
      }
    } else if (name === "password") {
      setPassword(value);
      if (value) {
        setPasswordError("");
      }
    }
  };

  const handleFocus = (inputName) => {
    if (inputName === "username") {
      setIsFocusedUsername(true);
      setUsernameError("");
    } else if (inputName === "password") {
      setIsFocusedPassword(true);
      setPasswordError("");
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "username" && !value) {
      setIsFocusedUsername(false);
    }
    if (name === "password" && !value) {
      setIsFocusedPassword(false);
    }
  };

  const handleLogout = () => {
    setUsername("");
    setPassword("");
    setShowPopup(false);
    setIsBigWindow(false);
    setShowVideo(false);
  };

  const handleManageWallets = () => {
    setShowPopup(false);
    setShowWalletPopup(true);
  };

  const handleRedeemGoblin = () => {
    setShowPopup(false);
    setShowRedeemPopup(true);
    setIsBigWindow(true);
  };

  const handleBack = () => {
    setShowPopup(true);
    setShowWalletPopup(false);
    setShowRedeemPopup(false);
    setIsBigWindow(false);
  };

  return (
    <div className="account-page">
      <section className={`account-section ${isBigWindow ? "big-window" : ""}`}>
        <div
          className={`account-form ${
            showPopup || showWalletPopup || showRedeemPopup ? "hide" : ""
          }`}
          style={{
            backgroundImage: `url(${accountBg})`,
            backgroundSize: "100% 100%",
            backgroundPosition: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div
              className={`form-group ${
                (isFocusedUsername || username) && !usernameError
                  ? "active"
                  : ""
              }`}
            >
              <label
                htmlFor="username"
                className={`desc-2 ${usernameError ? "label-hidden" : ""}`}
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={handleChange}
                onFocus={() => handleFocus("username")}
                onBlur={handleBlur}
                autoComplete="off"
                className="desc-2"
              />
              {usernameError && (
                <div className="error-message desc-2">{usernameError}</div>
              )}
            </div>

            <div
              className={`form-group ${
                (isFocusedPassword || password) && !passwordError
                  ? "active"
                  : ""
              }`}
            >
              <label
                htmlFor="password"
                className={`desc-2 ${passwordError ? "label-hidden" : ""}`}
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handleChange}
                onFocus={() => handleFocus("password")}
                onBlur={handleBlur}
                autoComplete="off"
                className="desc-2"
              />
              {passwordError && (
                <div className="error-message desc-2">{passwordError}</div>
              )}
            </div>

            <ButtonWithLightning
              asButton={true}
              label="login"
              onClick={handleSubmit}
            />
            <p className="desc-1">
              Don't have an account?{" "}
              <a className="desc-1" href="#">
                Register in-game!
              </a>
            </p>
          </form>
        </div>

        {showPopup && !showWalletPopup && !showRedeemPopup && (
          <div className="popup">
            <h1 className="title-h1">Account Panel</h1>
            <div className="popup-hero">
              <div
                className="popup-hero__content"
                style={{
                  backgroundImage: `url(${accountPanelBg})`,
                  backgroundSize: "100% 100%",
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="logout" onClick={handleLogout}>
                  <p className="p desc-1">Log Out</p>
                  <img src="/images/account/logout.svg" alt="log out" />
                </div>
                <h3 className="title-h1">Welcome, {username}!</h3>
                <table className="balance-table">
                  <thead>
                    <tr>
                      <th className="desc-1">Type</th>
                      <th className="desc-1">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="desc-1">Your Balance</td>
                      <td className="desc-1">0</td>
                    </tr>
                    <tr>
                      <td className="desc-1">Current Rate</td>
                      <td className="desc-1">
                        10 points = 1000 $GOBLIN Tokens
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="menu">
                  <div className="menu-title">
                    <p className="desc-1">Menu</p>
                    <span></span>
                  </div>
                  <div className="menu-list">
                    <a
                      className="desc-2"
                      href="#"
                      onClick={handleManageWallets}
                    >
                      <img src="/images/account/icon-3.svg" alt="icon" />
                      Manage your Wallets
                    </a>
                    <a className="desc-2" href="#" onClick={handleRedeemGoblin}>
                      <img src="/images/account/icon-1.svg" alt="icon" />
                      Redeem your $GOBLIN tokens
                    </a>
                    <a className="desc-2" href="#">
                      <img src="/images/account/icon-2.svg" alt="icon" />
                      Buy $GOBLIN Coins
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showWalletPopup && (
          <div className="popup wallet">
            <h1 className="title-h1">Your Wallets</h1>
            <div className="popup-hero">
              <div
                className="popup-hero__content"
                style={{
                  backgroundImage: `url(${accountBg})`,
                  backgroundSize: "100% 100%",
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p className="desc-1">Add new wallet</p>
                <ButtonWithLightning
                  asButton={true}
                  label="Connect Wallet"
                  // onClick={connectToWallet}
                />
                <button className="button-back desc-1" onClick={handleBack}>
                  <img src="/images/account/account-back.svg" alt="back" /> Go
                  Back
                </button>
              </div>
            </div>
          </div>
        )}

        {showRedeemPopup && (
          <div className="popup claim">
            <h1 className="title-h1">Claim $Goblin Tokens</h1>
            <div className="popup-hero">
              <div
                className="popup-hero__content"
                style={{
                  backgroundImage: `url(${accountClaimBg})`,
                  backgroundSize: "100% 100%",
                  backgroundPosition: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="redeem-notes">
                  <div className="note-title">
                    <img src="/images/account/note.svg" alt="icon" />
                    <h2 className="desc-1">Note</h2>
                  </div>
                  <ul className="note-list">
                    <li className="desc-2">
                      In order to claim $GOBLIN, you have to be logged out of
                      the game.
                    </li>
                    <li className="desc-2">Minimum claim amount is $50</li>
                    <li className="desc-2">
                      Claiming will cost tokens for gas.
                    </li>
                  </ul>
                </div>
                <div className="redeem-info">
                  <div className="redeem-info-list">
                    <img
                      className="border"
                      src={borderInputTopData}
                      alt="border"
                    />
                    <img
                      className="icon"
                      src="/images/account/icon-6.svg"
                      alt="icon"
                    />
                    <p className="desc-1">
                      {" "}
                      0.0021 ETH ≈ 9679.45 $GOBLIN ($5.12)
                    </p>
                  </div>
                  <div className="redeem-info-list">
                    <img
                      className="border"
                      src={borderInputTopData}
                      alt="border"
                    />
                    <img
                      className="icon"
                      src="/images/account/icon-4.svg"
                      alt="icon"
                    />
                    <p className="desc-1"> 10 points = 1,000 $GOBLIN tokens</p>
                  </div>
                  <div className="redeem-info-list">
                    <img
                      className="border"
                      src={borderInputTopData}
                      alt="border"
                    />
                    <img
                      className="icon"
                      src="/images/account/icon-5.svg"
                      alt="icon"
                    />
                    <p className="desc-1"> 0 points</p>
                  </div>
                </div>
                <div className="redeem-actions">
                  <label className="desc-1">Points to exchange</label>
                  <div className={`pointstoexch ${pointsError ? "error" : ""}`}>
                    <img
                      className="border default"
                      src={borderInputCoinDefaultData}
                      alt="border"
                    />
                    <img
                      className="border hover"
                      src={borderInputCoinHoverData}
                      alt="border"
                    />
                    <img
                      className="border error"
                      src={borderInputCoinErrorData}
                      alt="border"
                    />
                    <input
                      type="number"
                      placeholder="10"
                      value={points}
                      onChange={handlePointsChange}
                    />
                    {pointsError && (
                      <span className="desc-1 error-message">
                        {pointsError}
                      </span>
                    )}
                  </div>

                  <label className="desc-1">Transfer to Wallet</label>
                  <div className="chosearrow">
                    <img
                      className="border default"
                      src={borderInputCoinDefaultData}
                      alt="border"
                    />
                    <img
                      className="border hover"
                      src={borderInputCoinHoverData}
                      alt="border"
                    />
                    <img
                      className="border error"
                      src={borderInputCoinErrorData}
                      alt="border"
                    />
                    <select className="desc-1">
                      <option>Choose a Wallet</option>
                    </select>
                    <img
                      className="choose"
                      src="/images/account/chose.svg"
                      alt="arrow"
                    />
                  </div>
                  <ButtonWithLightning
                    asButton={true}
                    label="Claim Tokens"
                    onClick={handleClaim}
                  />
                </div>
                <button className="button-back desc-1" onClick={handleBack}>
                  <img src="/images/account/account-back.svg" alt="back" /> Go
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      </section>

      <div className={`transition-container ${showVideo ? "hide" : ""}`}>
        <FireEffect />
      </div>

      {showVideo && (
        <div className={`video-background ${isVideoVisible ? "visible" : ""}`}>
          <video
            playsInline={true}
            webkit-playsinline="true"
            preload="auto"
            autoPlay
            loop
            muted
            loading="lazy"
            className="hero-background-video"
          >
            <source src="/videos/Tornado-vp9-chrome.webm" type="video/webm" />
            <source src="/videos/Tornado.mp4" type="video/mp4" />
          </video>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default transition(Account);
