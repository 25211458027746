import { motion } from "framer-motion";
import React from "react";

const NUM_BLOCKS = 8;

const transition = (OriginalComponent) => {
  return () => {
    const blocks = Array.from({ length: NUM_BLOCKS }, (_, index) => index);

    return (
      <>
        <OriginalComponent />

        {blocks.map((i) => (
          <motion.div
            key={`slide-in-${i}`}
            className="slide-in-block"
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 1 }}
            transition={{
              duration: 0.5,
              ease: [0.22, 1, 0.36, 1],
              delay: i * 0.1,
            }}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: `${(i * 100) / NUM_BLOCKS}%`,
              width: `${100 / NUM_BLOCKS}vw`,
              background: "black",
              transformOrigin: "top",
              zIndex: 1000 - i,
            }}
          />
        ))}

        {blocks.map((i) => (
          <motion.div
            key={`slide-out-${i}`}
            className="slide-out-block"
            initial={{ scaleY: 1 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 0 }}
            transition={{
              duration: 0.5,
              ease: [0.22, 1, 0.36, 1],
              delay: i * 0.1,
            }}
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: `${(i * 100) / NUM_BLOCKS}%`,
              width: `${100 / NUM_BLOCKS}vw`,
              background: "black",
              transformOrigin: "top",
              zIndex: 1000 - i,
            }}
          />
        ))}
      </>
    );
  };
};

export default transition;
