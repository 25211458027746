import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.sass";
import { gsap } from "gsap";
import SvgIcon from "./SvgIcon";

const NUM_BLOCKS = 10;
const TRANSITION_DELAY = NUM_BLOCKS * 0.1 + 0.5;

const NavBar = ({ toggleMenu, isMenuOpen }) => {
  const location = useLocation();
  const [showSvg, setShowSvg] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(
    "/images/header/Header_Desktop.webp"
  );

  useEffect(() => {
    setShowSvg(false);
    const timer = setTimeout(() => {
      setShowSvg(true);
    }, TRANSITION_DELAY * 1000);

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 767px)").matches) {
        setBackgroundImage("/images/header/Header_Mobile.webp");
      } else if (
        window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches
      ) {
        setBackgroundImage("/images/header/Header_Tablet.webp");
      } else {
        setBackgroundImage("/images/header/Header_Desktop.webp");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const heroLinks = [
    { to: "/", label: "Home" },
    { to: "/playnow", label: "Play" },
    { to: "/features", label: "Features" },
  ];

  const supLinks = [
    { to: "https://docs.web3scape.io/", label: "Docs", external: true },
    { to: "/highscores", label: "Highscores" },
  ];

  const handleHomeClick = () => {
    gsap.set(".footer", {
      y: "100%",
    });
  };

  return (
    <header className={`header ${isScrollingUp ? "visible" : "hidden"}`}>
      <div
        className="nav"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="logo">
          <Link className="nav-link" to="/" onClick={handleHomeClick}>
            <img src="/images/header/Logo_Horizontal.webp" alt="logo" />
          </Link>
        </div>

        <div className="hero-links">
          {heroLinks.map((item, index) => (
            <div className="nav-item" key={index}>
              <Link
                className="nav-link desc-1"
                to={item.to}
                onClick={item.label === "Home" ? handleHomeClick : null}
              >
                {item.label}
                <SvgIcon isVisible={location.pathname === item.to} />
              </Link>
            </div>
          ))}
        </div>

        <div className="sup-links">
          {supLinks.map((item, index) => (
            <div className="nav-item" key={index}>
              {item.external ? (
                <a
                  className="nav-link desc-1"
                  href={item.to}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                  <SvgIcon isVisible={false} />
                </a>
              ) : (
                <Link className="nav-link desc-1" to={item.to}>
                  {item.label}
                  <SvgIcon isVisible={location.pathname === item.to} />
                </Link>
              )}
            </div>
          ))}
        </div>

        <div className="nav-item account-panel">
          <Link className="nav-link desc-1 accountpanel" to="/account">
            Account panel
            <SvgIcon isVisible={location.pathname === "/account"} />
          </Link>
        </div>
        <div className="mobile-burger" onClick={toggleMenu}>
          <a>
            <img src="/images/header/burger-button.webp" alt="menu" />
            <p className="desc-1">{isMenuOpen ? "Close" : "Menu"}</p>{" "}
          </a>
        </div>
      </div>
      <img
        className="shield"
        src="/images/header/Logo_Shield.webp"
        alt="logo"
      />
    </header>
  );
};

export default NavBar;
