// SvgIcon.js
import { motion } from "framer-motion";

const SvgIcon = ({ isVisible }) => (
  <motion.svg
    initial={{ opacity: 0 }}
    animate={{ opacity: isVisible ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-6.10352e-05 3.76927L-6.10352e-05 3.23466H0.591326C1.34952 3.23466 2.12666 3.17982 2.70289 2.53829C3.27911 1.89676 3.21114 0.842022 3.21114 0L3.76917 0L3.76917 3.76927L-6.10352e-05 3.76927Z"
      fill="#82FBBD"
    />
    <path
      d="M6.99994 3.76927V3.23466H6.40855C5.65036 3.23466 4.87322 3.17982 4.29699 2.53829C3.72077 1.89676 3.78873 0.842022 3.78873 0L3.23071 0L3.23071 3.76927L6.99994 3.76927Z"
      fill="#82FBBD"
    />
    <path
      d="M6.99994 3.23073V3.76534H6.40855C5.65036 3.76534 4.87322 3.82018 4.29699 4.46171C3.72077 5.10324 3.78873 6.15798 3.78873 7L3.23071 7L3.23071 3.23073L6.99994 3.23073Z"
      fill="#82FBBD"
    />
    <path
      d="M-6.10352e-05 3.23073L-6.10352e-05 3.76534H0.591326C1.34952 3.76534 2.12666 3.82018 2.70289 4.46171C3.27911 5.10324 3.21114 6.15798 3.21114 7L3.76917 7L3.76917 3.23073L-6.10352e-05 3.23073Z"
      fill="#82FBBD"
    />
  </motion.svg>
);

export default SvgIcon;
