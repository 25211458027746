import React, { useState, useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { gsap } from "gsap";
import "./preloader.sass";

const Preloader = ({ onComplete }) => {
  const [progress, setProgress] = useState(0);
  const [shouldPlayPreloader, setShouldPlayPreloader] = useState(true);
  const preloaderRef = useRef(null);

  useEffect(() => {
    const hasSeenPreloader = localStorage.getItem("hasSeenPreloader");
    if (hasSeenPreloader) {
      setShouldPlayPreloader(false);
      onComplete();
    }
  }, [onComplete]);

  useEffect(() => {
    if (!shouldPlayPreloader) return;

    gsap.to(".left_lottie", {
      x: 0,
      duration: 3,
      ease: "power2.inOut",
    });
    gsap.to(".right_lottie", {
      x: 0,
      duration: 3,
      ease: "power2.inOut",
    });
    gsap.to(".preloader-star", {
      y: 0,
      duration: 3,
      ease: "power2.inOut",
    });

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          gsap.to(preloaderRef.current, {
            opacity: 0,
            duration: 0.3,
            ease: "power2.inOut",
            onComplete: () => {
              localStorage.setItem("hasSeenPreloader", "true");
              onComplete();
            },
          });
          return 100;
        }
        return prev + 1;
      });
    }, 40);

    return () => clearInterval(interval);
  }, [shouldPlayPreloader, onComplete]);

  if (!shouldPlayPreloader) {
    return null;
  }

  return (
    <div className="preloader" ref={preloaderRef}>
      <div className="progress-container">
        <p className="title-h2 progress-text">{progress}%</p>
        <div className="lottie-animations">
          <Player
            autoplay
            loop
            src="/animations/preloader_element.json"
            className="preload-lottie left_lottie"
          />
          <Player
            autoplay
            loop
            src="/animations/preloader_element.json"
            className="preload-lottie right_lottie"
          />
        </div>
        <div className="fromstar">
          <svg
            className="preloader-star"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 22C11.8421 22.5682 21.3473 32.1622 22 44C22.6527 32.1622 32.1579 22.5682 44 22C32.1579 21.4318 22.6527 11.8378 22 0C21.3473 11.8378 11.8421 21.4318 0 22Z"
              fill="#82FBBD"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
