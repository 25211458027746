import "./App.sass";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";

import NavBar from "./Components/header/NavBar";
import MobileMenu from "./Components/mobilemenu/MobileMenu";
import Home from "./Components/pages/home/Home";
import Features from "./Components/pages/features/Features";
import PlayNow from "./Components/pages/playnow/PlayNow";
import Account from "./Components/pages/account/Account";
import HighScores from "./Components/pages/highscores/HighScores";
import Footer from "./Components/footer/Footer";

function App() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const shouldRenderFooter = !(location.pathname === "/account");

  return (
    <>
      <NavBar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <MobileMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/playnow" element={<PlayNow />} />
          <Route path="/highscores" element={<HighScores />} />
          <Route path="/features" element={<Features />} />
          <Route path="/account" element={<Account />} />
        </Routes>
      </AnimatePresence>
      {shouldRenderFooter && <Footer />}
    </>
  );
}

export default App;
