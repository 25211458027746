import { useState, useEffect } from "react";

const useResponsiveSettings = (images) => {
  const [backgroundImage, setBackgroundImage] = useState(images.desktop);
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 767px)").matches) {
        setBackgroundImage(images.mobile);
        setDeviceType("mobile");
      } else if (
        window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches
      ) {
        setBackgroundImage(images.tablet);
        setDeviceType("tablet");
      } else {
        setBackgroundImage(images.desktop);
        setDeviceType("desktop");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [images]);

  return { backgroundImage, deviceType };
};

export default useResponsiveSettings;
